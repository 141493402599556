<template>
  <div>
    <!-- picE22D07BD72C661D85157FF528460F438.jpg -->
    <img class="rounded img-large" src="@/assets/news-network/maelstorm-slams-minifig-mission.jpg">
    <p>
      LEGO technicians and scientists from the Bradford Rant Institute of Cosmic Kinesis (B.R.I.C.K.) have made a startling discovery. A recent scan of the <router-link to="/help-the-minifigs/mission/169682">Great Minifig Mission website</router-link> revealed that the Maelstrom has gained access to the site and possibly infected it.
    </p>
    <p>
      ”We thought that The Maelstrom might be increasing the minifig total. Why? To make fans believe that no more minifigs need to be sent to LEGO Universe,” said Dr John Deutschendorf from B.R.I.C.K. ”We now believe that our minifig totals are too high.”
    </p>
    <p>
      The scientists will continue to work alongside LEGO technicians to get accurate readings. The team also plans to improve security between Earth and LEGO Universe.
    </p>
    <p>
      ”We must keep the Maelstrom out of the Great Minifig Mission website,” said Dr. Deutschendorf, ”My team will alert fans when we know how many minifigs are actually being sent to LEGO Universe. More work will need to be done, but we are confident LEGO fans are up to the challenge. Their work so far has been amazing!”
    </p>
    <p>
      Lock your browser here on LEGOuniverse.com for more details on this developing mystery.
    </p>
  </div>
</template>
